import axios from "../axios";
import apiConstant from "../constants/api-constant";

const request = {
  getLoginCode(data = {}) {
    const url = apiConstant.GET_LOGIN_CODE_URL;
    return axios.request({
      url,
      data,
    });
  },

  login(data = {}) {
    const url = apiConstant.LOGIN_URL;
    return axios.request({
      url,
      data,
    });
  },
  getUserInfo(data = {}) {
    const url = apiConstant.GET_USER_INFO_URL;
    return axios.request({
      url
    });
  },
  getOrderList(data = {}) {
    const url = apiConstant.GET_ORDER_LIST;
    return axios.request({
      url,
      data
    });
  },
  getOrderDetail(data = {}) {
    const url = apiConstant.GET_ORDER_DETAIL+data.id;
    return axios.request({
      url,
      data
    });
  },
  bidOrder(data = {}) {
    const url = apiConstant.TAKE_ORDER;
    return axios.request({
      url,
      data
    });
  },
  unbidOrder(data = {}) {
    const url = apiConstant.UNBID_ORDER;
    return axios.request({
      url,
      data
    });
  },
  cancelOrder(data = {}) {
    const url = apiConstant.BACK_ORDER;
    return axios.request({
      url,
      data
    });
  },
  getWaitOrderList(data = {}) {
    const url = apiConstant.GET_WAIT_ORDER_LIST;
    return axios.request({
      url,
      data
    });
  },
  getProcessedOrderList(data = {}) {
    const url = apiConstant.GET_PROCESSED_ORDER_LIST;
    return axios.request({
      url,
      data
    });
  },
  uploadOrderTicketCode(data = {}) {
    const url = apiConstant.UPLOAD_ORDER_TICKET_CODE;
    return axios.request({
      url,
      data
    });
  },
  unlockOrder(data = {}) {
    const url = apiConstant.UNLOCK_SEAT+"/"+data.id;
    return axios.request({
      url,
      data
    });
  },
  getSeatPic(data = {}) {
    const url = apiConstant.GET_SEAT_PIC;
    return axios.request({
      url,
      data
    });
  },
  getCinemaRuleList(data = {}) {
    const url = apiConstant.GET_CINEMA_RULE_LIST;
    return axios.request({
      url,
      data
    });
  },
  setCinemaRuleStatus(data = {}) {
    const url = apiConstant.SET_CINEMA_RULE_STATUS;
    return axios.request({
      url,
      data
    });
  },
  uploadImage(data = {}) {
    const url = apiConstant.UPLOAD_IMAGE;
    return axios.request({
      url,
      data,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout:30000
    });
  },
  setAutoStatus(data = {}) {
    const url = apiConstant.SET_AUTO_STATUS;
    return axios.request({
      url,
      data
    });
  },
  getWithdrawAmount(data = {}) {
    const url = apiConstant.GET_WITHDRAW_AMOUNT;
    return axios.request({
      url,
      data
    });
  },
  getWithdrawList(data = {}) {
    const url = apiConstant.GET_WITHDRAW_LIST;
    return axios.request({
      url,
      data
    });
  },
  applyWithdraw(data = {}) {
    const url = apiConstant.APPLY_WITHDRAW;
    return axios.request({
      url,
      data
    });
  },
  hasNewOrder(data = {}) {
    const url = apiConstant.HAS_NEW_ORDER;
    return axios.request({
      url,
      data
    });
  },
  hasMyOrder(data = {}) {
    const url = apiConstant.HAS_MY_ORDER;
    return axios.request({
      url,
      data
    });
  },
  changePwd(data = {}) {
    const url = apiConstant.CHANGE_PWD;
    return axios.request({
      url,
      data
    });
  },
  makeCode(data = {}) {
    const url = apiConstant.MAKE_CODE;
    return axios.request({
      url,
      data
    });
  },
  bidRecord(data = {}) {
    const url = apiConstant.BID_RECORD;
    return axios.request({
      url,
      data
    });
  },


  getProvinceCityList(data = {}) {
    const url = apiConstant.PROVINCE_CITY_LIST;
    return axios.request({
      url,
      params:data,
      method: 'GET'
    });
  },
  getGpLineList(data = {}) {
    const url = apiConstant.GPLINE_LIST;
    return axios.request({
      url,
      params:data,
      method: 'GET'
    });
  },
  getCinemaList(data = {}) {
    const url = apiConstant.CINEMA_LIST;
    return axios.request({
      url,
      data
    });
  },
  getDefaultHallList(data = {}) {
    const url = apiConstant.DEFAUL_HALL_LIST;
    return axios.request({
      url,
      params:data,
      method: 'GET'
    });
  },
  getMovieList(data = {}) {
    const url = apiConstant.MOVIE_LIST;
    return axios.request({
      url,
      params:data,
      method: 'GET'
    });
  },
  getConfigDetail(data = {}) {
    const url = apiConstant.GET_RULE_DETAIL;
    return axios.request({
      url,
      params:data,
      method: 'GET'
    });
  },
  addConfig(data = {}) {
    const url = apiConstant.ADD_RULE;
    return axios.request({
      url,
      data,
    });
  },
  updateConfig(data = {}) {
    const url = apiConstant.UPDATE_RULE;
    return axios.request({
      url,
      data,
    });
  },
  updateConfigStatus(data = {}) {
    const url = apiConstant.UPDATE_RULE_STATUS;
    return axios.request({
      url,
      data,
    });
  },
  deleteConfig(data = {}) {
    const url = apiConstant.DELETE_RULE;
    return axios.request({
      url,
      data,
    });
  },
}

export default request;
