<template>
  <div class="login-wrap flex-column">
    <div class="header flex-column flex-middle">
      <van-image width="44" height="44" fit="contain" :src="require(`@/assets/login-icon.png`)" />
      <p>24H电影票</p>
    </div>
    <div class="main-card">
      <van-cell-group>
        <van-field v-model="form.phone" type="number" clearable ref="phone" maxlength="11" placeholder="请输入手机号" />
        <van-field v-model="form.verifyCode"  clearable ref="verifyCode" maxlength="24" placeholder="输入手机版动态密码">
          <template #button>
            <!-- <van-button size="small" type="primary">发送验证码</van-button> -->
            <!-- <div class="verifyCodeBtn" @click="handleVerifyCode">{{verifyText}}</div> -->
          </template>
        </van-field>
        <div class="loginBtn flex-middle flex-center" @click="login" >
          登录
        </div>
      </van-cell-group>
    </div>
    <div class="footer flex-row fx-end flex-center">
      <span>
        若存在登录问题，请联系相应管理员
      </span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex';
import { validField } from '@/libs/common-utils/common-utils';
import router from '@/router';
import store from '@/store';
import { wxLogin } from '@/libs/wx-utils';

export default {
  data() {
    return {
      form: {
        phone: '',
        verifyCode: '',
      },
      verifyText: '获取验证码',
      disabledVerify: false,
      loadingVerify: false,
      redirectUri: '',
    };
  },
  methods: {
    ...mapActions(['sendVerifyCode', 'loginByVerify']),
    handleVerifyCode() {
      if(this.loadingVerify){
        return;
      }
      if (this.form.phone == '') {
        this.$toast('请输入手机号码');
        return false;
      }
      let valid = validField(this.form.phone, 'phoneNumber');
      if (!valid) {
        this.$toast('请输入正确的手机号码');
        return;
      }
      this.loadingVerify = true;
      this.sendVerifyCode(this.form.phone).then(() => {
        this.$toast.success('发送成功');
        this.$refs.verifyCode.focus();
        this.loadingVerify = false;
      });
    },
    closeTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    login() {
      if (this.form.phone == '') {
        this.$toast('请输入手机号码');
        return false;
      }
      let valid = validField(this.form.phone, 'phoneNumber');
      if (!valid) {
        this.$toast('请输入正确的手机号码');
        return;
      }

      if (this.form.verifyCode == '') {
        this.$toast('请输入验证码');
        return false;
      }
      //这里需要验证 TODO
      let params = {
        mobile: this.form.phone,
        verifyCode: this.form.verifyCode,
        redirectUri: this.redirectUri,
      };
      this.loginByVerify(params).then((res)=>{
        this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
      }).catch(({ errorCode, errorMsg }) => {
        this.$toast.fail(errorCode + errorMsg);
      });
    },
  },
  mounted() {
    this.redirectUri = location.href;
  },
  computed: {
    ...mapGetters(['platform', 'verifyTime', 'openId']),
    disabledLogin() {
      for (let key in this.form) {
        if (this.form[key] == '') {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    verifyTime: {
      immediate: true,
      handler(val) {
        let _this = this;
        if (val && this.timer == null) {
          setTimeout(() => {
            this.timer = setInterval(tempFuc(), 1000);
          }, 10);
        }
        function tempFuc() {
          let currentDateTime = new Date().getTime();
          let dateTimeDiff = val - currentDateTime;
          if (dateTimeDiff > 1000) {
            _this.verifyText = `${parseInt(dateTimeDiff / 1000)}秒后获取`;
            _this.disabledVerify = true;
          } else {
            _this.disabledVerify = false;
            _this.verifyText = '获取验证码';
            // _this.setVerifyDateTime(null);
            _this.closeTimer();
          }
          return tempFuc;
        }
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter')
    console.log(router.options)
    console.log(to)
    console.log(from)
    console.log(navigator)
    
   
        let href = `${location.protocol}//${location.host}${router.options.base || ''}${to.fullPath}?redirect=${from.fullPath}`
        let platform = store.getters.platform;
        console.log(href)
        console.log(platform)
        // return
        if (platform == 'wx') {
            wxLogin(href).then((login) => {
              console.log('1111')
              console.log(login)
                if (login) {
                    next(to.query.redirect);
                } else {
                    next();
                }
            }).catch((prevent) => {
                !prevent ? next() : '';
            });
        } else {
            next();
        }
    }
};
</script>
<style scoped lang="scss">
.login-wrap {
  position: relative;
  height: 100%;
  .header {
    margin: 40px 0;
    font-size: 14px;
  }
  .van-cell-group {
    margin: 16px;
    margin-top: 0;
    border-radius: 6px;
    overflow: hidden;
    .van-cell {
      padding: 18px 16px;
    }
    .verifyCodeBtn {
      padding: 2px 12px;
      color: white;
      border-radius: 4px;
      background-color: #8565bd;
    }
    .loginBtn {
      margin: 20px 16px;
      height: 42px;
      width: auto;
      color: white;
      border-radius: 4px;
      background-color: #8565bd;
    }
  }
  .footer {
    flex-grow: 1;
    margin-bottom: 32px;
    bottom: 0;
    text-align: center;
    font-size: 12px;
    color: #999999;
  }
}
</style>
