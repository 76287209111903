<template>
<div>
    <van-nav-bar :title="totalCountStr" fixed :placeholder="true" />
    <div class="home-wrap">
        <van-pull-refresh v-model="refresh" @refresh="onRefresh" style="height: 100% !important;">
            <div class="main-card-wrap" v-for="item in tableData">
                <van-row class="flex-between">
                    <div style="width:100%; display: flex; align-items: center; justify-content: flex-end;padding-right: 20px;">
                        <!-- <span style="color: red; font-weight: bold;"> {{ item.remaining }}秒</span> -->
                        <van-count-down :time="item.remaining * 1000" format="ss 秒" />
                        <!-- <van-count-down :time="30 * 60 * 60 * 1000" /> -->
                    </div>

                </van-row>
                <van-row class="flex-between">
                    <div class="left flex-column flex-around">
                        <div class="title">{{item.movie_name}} <span class="priceTitle">({{item.channel_price}} * {{item.quantity}})</span></div>
                        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                        <div class="subtitle">{{item.city_name}} | {{item.cinema_name}}</div>
                        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                        <div class="otherTitle">{{item.play_time_text}} | {{item.hall_name}}</div>
                        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                        <!-- <div style="display: flex; justify-content: space-between; align-items: center;">
                    <el-input v-model="item.bid_price" class="my-custom-input" clearable @clear="clearBidPrice(order)">
                      <template slot="prepend">
                        <span>建议</span>
                        <span class="order-price"> {{ item.order_price }}</span>
                        <span>以内</span>
                      </template>
                    </el-input>
                    <el-button v-if="!select.all" style="margin-left: 5px;" type="info" size="mini" @click="clearBidPrice(item)">取消</el-button>
                    <el-button style="margin-left: 5px;" type="primary" size="mini" @click="bidOrder(item)">报价</el-button>
                  </div> -->

                    </div>
                    <div class="right flex-middle flex-center">
                        <van-image width="90px" height="120px" fit="fit" :src="item.logo+'_200x200'" />
                    </div>
                </van-row>
                <van-row class="flex-between">
                    <div class="left flex-column flex-around">
                        <van-field v-model="item.bid_price" type="number" clearable @input="handleBlur(item)">
                            <template #label>
                                <div style="width: 100px; display: flex; align-items: left;">
                                    <span>建议</span>
                                    <span style="color: red; font-weight: bold;"> {{ item.order_price }}</span>
                                    <span>以内:</span>
                                </div>
                            </template>
                        </van-field>
                        <!-- <van-col span="12">
                                <van-field v-model="item.yzm1" name="验证码1" label="验证码1" placeholder="验证码1" />
                            </van-col> -->

                    </div>
                    <div v-if="shouldShowCancelButton(item)" class="right flex-middle flex-center">
                        <van-button color="#8565bd" :style="{width:'60px',height:'32px',marginTop: '6px',borderRadius: '2px',fontSize:'12px'}" @click="unbidOrder(item)" size="mini" plain>取消</van-button>
                    </div>
                    <div class="right flex-middle flex-center">
                        <van-button color="#8565bd" :style="{width:'60px',height:'32px',marginTop: '2px',borderRadius: '2px',fontSize:'12px'}" @click="bidOrder(item)" size="mini" plain>报价</van-button>
                    </div>
                </van-row>
            </div>
        </van-pull-refresh>
    </div>
</div>
</template>

<script>
import Api from '@/api-service'
export default {
    name: "Main.vue",
    data() {
        return {
            tableData: [],
            editData: {}, // 保存标记中的数据
            totalCountStr: '订单池',
            refresh: false,
            timer: null,
            price: '',
            select: {
                // begin_price: '',
                // end_price: '',
                province_id: [],
                city_code: [],
                cinema_name: '',
                movie_id: [],
                all: true
            },
        }
    },
    created() {
        this.fetchData();
    },
    mounted() {
        setTimeout(() => {
            this.timer = setInterval(() => {
                this.fetchData()
            }, 5000);
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
    methods: {
        onRefresh() {
            setTimeout(() => {
                if (this.refresh) {
                    this.tableData = [];
                    this.refresh = false;
                }
                this.fetchData();
            }, 500)
        },
        fetchData() {
            Api.getOrderList({
                all: true
            }).then(res => {
                const newList = res.list.map(item => {
                    if (item.order_id in this.editData) {
                        if (this.editData[item.order_id] != '' && this.editData[item.order_id] > 0) {
                            item.bid_price = this.editData[item.order_id];
                        }
                    }
                    return item;

                });
                this.tableData = newList;
                this.totalCountStr = '订单池' + (res.total > 0 ? ' (' + res.total + ')' : '');
            })
        },
        bidOrder(item) {
            Api.bidOrder({
                order_id: item.order_id,
                bid_price: item.bid_price,
            }).then(res => {
                this.$toast.success("报价成功");
                this.fetchData();
            });
        },
        unbidOrder(item) {
            Api.unbidOrder({
                order_id: item.order_id,
            }).then(res => {
                this.$toast.success("取消成功");
                this.editData[item.order_id] = ''
                this.fetchData();
            });
        },
        shouldShowCancelButton(item) {
            return item.bid_time !== '0001-01-01T00:00:00Z';
        },
        handleBlur(item) {
            // 当输入框失去焦点时，处理数值
            console.log('handleBlur')
            console.log(item)
            const value = item.bid_price;
            if (value) {
                // 如果输入了值，格式化为一位小数
                // item.bid_price = parseFloat(value).toFixed(1);
                this.editData[item.order_id] = item.bid_price
                console.log('handleBlur')
                console.log(this.editData)
            } else {
                this.editData[item.order_id] = ''
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.home-wrap {
    height: 100%;
    background-color: #f3f3f3;

    .main-card-wrap {
        margin: 10px 10px;
        padding: 16px;
        border-radius: 6px;
        background-color: white;
        -moz-box-shadow: 2px 2px 3px #ccc;
        -webkit-box-shadow: 2px 2px 3px #ccc;
        box-shadow: 2px 2px 3px #ccc;

        .left {
            .title {
                font-size: 18px;
            }

            .subtitle {
                font-size: 14px;
                margin-top: 6px;
            }

            .priceTitle {
                font-size: 16px;
                color: #c75450;

            }

            .otherTitle {
                font-size: 14px;
                margin-top: 6px;
                color: #999;
            }

            .btn {
                height: 34px;
                width: 120px;
                color: white;
                margin-top: 6px;
                border-radius: 4px;
                background-color: #8565bd;
            }
        }

        .right {
            margin-right: 8px
        }
    }
}

::v-deep .van-field__control {
    border-bottom: 1px solid #ccc;
    /* 定义下划线样式 */
}

.time_right {
    display: flex;
    justify-content: flex-end;
    /* 使子元素靠右显示 */
    align-items: center;
    /* 垂直居中 */
}
/* 自定义 van-count-down 的样式 */
::v-deep .van-count-down {
    color: red;
    font-weight: bold;
}
</style>
