<template>
<div>
    <van-nav-bar :left-arrow="true" left-text="返回" title="自定义模式" fixed :placeholder="true" @click-left="goBack">
        <van-icon v-if="this.configId" name="delete" slot="right" @click="deleteRule" />
    </van-nav-bar>
    <div>
        <van-cell title="规则名称" style="display: flex;align-items: center;">
            <van-field v-model="ruleInfo.name" placeholder="请输入规则名称" rows="1" right clearable style="display: flex;justify-content: right;text-align: right;" />
        </van-cell>
        <!-- <van-field v-model="ruleInfo.name" label="规则名称" placeholder="请输入规则名称" rows="1" right clearable/> -->
        <van-cell is-link title="包含省" :value="ruleInfo.include_province_desc" @click="showProvinceSelect(1)">
        </van-cell>
        <van-cell is-link title="不包含省" :value="ruleInfo.exclude_province_desc" @click="showProvinceSelect(2)">
        </van-cell>
        <van-cell is-link title="包含城市" :value="ruleInfo.include_city_desc" @click="showCitySelect(1,true)">
        </van-cell>
        <van-cell is-link title="不包城市" :value="ruleInfo.exclude_city_desc" @click="showCitySelect(2,true)">
        </van-cell>
        <van-cell is-link title="包含品牌" :value="ruleInfo.line_name" @click="showGplineSelect(true)"></van-cell>
    </div>
    <div style="margin-top: 20px;">
        <van-cell is-link title="包含影院" :value="ruleInfo.include_cinema_desc" @click="showCinemaSelect(1)">
        </van-cell>
        <van-cell is-link title="不包含影院" :value="ruleInfo.exclude_cinema_desc" @click="showCinemaSelect(2)">
        </van-cell>
        <van-cell is-link title="包含影厅" :value="ruleInfo.include_hall_desc" @click="showHallSelect(1)">
        </van-cell>
        <van-cell is-link title="不包含影厅" :value="ruleInfo.exclude_hall_desc" @click="showHallSelect(2)">
        </van-cell>
        <van-cell is-link title="包含电影" :value="ruleInfo.include_movie_desc" @click="showMovieSelect(1)">
        </van-cell>
        <van-cell is-link title="不包含电影" :value="ruleInfo.exclude_movie_desc" @click="showMovieSelect(2)">
        </van-cell>
    </div>

    <van-cell-group style="margin-top: 20px;">
        <van-cell class="custom-cell" title="市场价" style="display: flex;align-items: center;">
            <van-row style="display: flex;align-items: center;">
                <van-field v-model="ruleInfo.begin_price" placeholder="最低" rows="1" clearable style="width: 100%; border: 1px solid #ebedf0; border-radius: 4px;" />
                <hr style="width: 16px; margin: 0px 0; border: 0; border-top: 1px solid #0a0a0a;" />

                <van-field v-model="ruleInfo.end_price" placeholder="最高" rows="1" clearable style="width: 100%; border: 1px solid #ebedf0; border-radius: 4px;" />

            </van-row>
        </van-cell>
        <van-cell class="custom-cell" title="放映日期" title-style="min-width:1rem;" style="display: flex; align-items: center;">
            <!-- <template slot="title">
                <span class="font-size3 bold custom-title-width">放映日期</span>
            </template> -->
            <van-row style="display: flex;align-items: center;">

                <van-field v-model="ruleInfo.begin_date" placeholder="开始" rows="1" clearable style="width: 100%; border: 1px solid #ebedf0; border-radius: 4px;" @click="changeDate(1)" />
                <hr style="width: 16px; margin: 0px 0; border: 0; border-top: 1px solid #0a0a0a;" />

                <van-field v-model="ruleInfo.end_date" placeholder="结束" rows="1" clearable style="width: 100%; border: 1px solid #ebedf0; border-radius: 4px;" @click="changeDate(2)" />

            </van-row>

        </van-cell>
        <van-cell class="custom-cell" title="放映时间" style="display: flex; align-items: center;">
            <van-row style="display: flex;align-items: center;">
                <van-field v-model="ruleInfo.begin_time" placeholder="开始" rows="1" clearable style="width: 100%; border: 1px solid #ebedf0; border-radius: 4px;" @click="changeTime(1)" />
                <hr style="width: 16px; margin: 0px 0; border: 0; border-top: 1px solid #0a0a0a;" />

                <van-field v-model="ruleInfo.end_time" placeholder="结束" rows="1" clearable style="width: 100%; border: 1px solid #ebedf0; border-radius: 4px;" @click="changeTime(2)" />

            </van-row>

        </van-cell>
        <van-cell is-link title="包含星期" :value="ruleInfo.week_desc" @click="showWeekSelect">
        </van-cell>
    </van-cell-group>

    <van-cell-group style="margin-top: 20px;">
        <van-cell title="票数" reverse-color>
            <van-checkbox-group v-model="ruleInfo.ticket_num" style="display: flex; flex-wrap: wrap; justify-content: space-around;">
                <van-checkbox :name="0" style="margin: 4px;">全部</van-checkbox>
                <van-checkbox :name="1" style="margin: 4px;">1张</van-checkbox>
                <van-checkbox :name="2" style="margin: 4px;">2张</van-checkbox>
                <van-checkbox :name="3" style="margin: 4px;">3张</van-checkbox>
                <van-checkbox :name="4" style="margin: 4px;">4张</van-checkbox>
            </van-checkbox-group>
            <!-- <van-radio-group v-model="radio3" style="display: flex; flex-wrap: wrap; justify-content: space-around;">
                    <van-radio name="0" style="margin: 4px;">全部</van-radio>
                    <van-radio name="1" style="margin: 4px;">1张</van-radio>
                    <van-radio name="2" style="margin: 4px;">2张</van-radio>
                    <van-radio name="3" style="margin: 4px;">3张</van-radio>
                    <van-radio name="4" style="margin: 4px;">4张</van-radio>
                </van-radio-group> -->
        </van-cell>
        <van-cell title="报价模式" reverse-color>
            <van-radio-group v-model="ruleInfo.bid_type" style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                <van-radio :name="3" style="margin: 4px;">折扣报价</van-radio>
                <van-radio :name="4" style="margin: 4px;">顶价报价</van-radio>
                <van-radio :name="5" style="margin: 4px;">一口价</van-radio>
                <van-radio :name="6" style="margin: 4px;">原价立减</van-radio>
            </van-radio-group>
            <van-field v-model="ruleInfo.discount" rows="1" clearable style="width: 100%; margin-top: 15px;border: 1px solid #ebedf0; border-radius: 4px;" />
            <span style="color:red;font-size:x-small; display:flex;text-align:left;">{{ bid_type_desc[ruleInfo.bid_type] }}</span>
        </van-cell>
    </van-cell-group>

    <van-cell-group style="margin-top: 20px;">
        <van-cell title="启动状态" style="display: flex; align-items: center;">
            <van-switch slot="right-icon" v-model="ruleInfo.status === 1" @change="setStatus" />
        </van-cell>
    </van-cell-group>

    <div style="padding: 40px 10px;">
        <van-button round type="danger" style="width: 100%;" @click="saveConfig">保存条件</van-button>
    </div>

    <!--城市弹窗-->
    <van-popup v-model="selectCity" position="bottom">
        <van-row>
            <van-search style="width: 100%;display: flex;justify-content: center;align-items: center;" placeholder="请输入城市" v-model="searchText" :clearable=false>
                <template #right-icon>
                    <van-row style="display: flex;justify-content: center;align-items: center;">
                        <van-icon v-if="searchText !== ''" name="clear" @click="clearCityClick" />
                        <van-button style="margin-left: 20px;" size="small" type="info" round=true @click="searchCityClick">搜索</van-button>
                    </van-row>

                </template>
            </van-search>
        </van-row>
        <van-tree-select :items="filterCityList" :main-active-index.sync="activeIndex" style="height: 420px;padding: 10px 10px 60px 10px;">
            <template slot="content">
                <van-row v-for="(city, index) in filterCityList[activeIndex].city_list" :key="index" style="background-color: white;margin-bottom: 10px;">
                    <van-cell :title="city.name" class="cell-with-margin">
                        <template #right-icon>
                            <van-checkbox v-model="city.checked" shape="square" @click="toggleCity(index)"></van-checkbox>
                        </template>
                    </van-cell>
                </van-row>

            </template>
        </van-tree-select>
        <van-row type="flex" class="bottom-row" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
            <van-col span="5">已选择{{selectCount}}个</van-col>
            <van-col span="16" style="text-align: right;">
                <van-button plain size="small" type="danger" @click="resetCity">重 置</van-button>
                <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveCity">确认选择</van-button>
            </van-col>
        </van-row>
    </van-popup>

    <!--省份弹窗-->
    <van-popup v-model="selectProvince" position="bottom">
        <!-- <van-row>
                    <van-search style="width: 100%;display: flex;justify-content: center;align-items: center;" placeholder="请输入品牌名" v-model="searchText" :clearable=false>
                        <template #right-icon>
                            <van-row style="display: flex;justify-content: center;align-items: center;">
                                <van-icon v-if="searchText !== ''" name="clear" @click="clearProvinceClick" />
                                <van-button style="margin-left: 20px;" size="small" type="info" round=true @click="searchProvinceClick">搜索</van-button>
                            </van-row>
        
                        </template>
                    </van-search>
                </van-row> -->
        <div style="height: 320px; overflow: scroll;padding: 10px 10px 60px 10px;">
            <van-row style="flex-wrap: wrap; margin: -8px;">
                <!-- 使用 flex-wrap 使子元素换行 -->
                <div v-for="(province, index) in filterProvinceList" :key="index" class="custom-checkbox" :class="{ 'checked': province.checked }" @click="provinceCheck(index)" style="margin: 8px;">
                    {{ province.label }} <!-- 显示复选框的序号 -->
                </div>
            </van-row>
        </div>
        <van-row type="flex" class="bottom-row" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
            <van-col span="5">已选择{{selectCount}}个</van-col>
            <van-col span="16" style="text-align: right;">
                <van-button plain size="small" type="danger" @click="resetProvince">重 置</van-button>
                <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveProvince">确认选择</van-button>
            </van-col>
        </van-row>
    </van-popup>

    <!--品牌弹窗-->
    <van-popup v-model="selectGpline" position="bottom">
        <van-row>
            <van-search style="width: 100%;display: flex;justify-content: center;align-items: center;" placeholder="请输入品牌名" v-model="searchText" :clearable=false>
                <template #right-icon>
                    <van-row style="display: flex;justify-content: center;align-items: center;">
                        <van-icon v-if="searchText !== ''" name="clear" @click="clearGplineClick" />
                        <van-button style="margin-left: 20px;" size="small" type="info" round=true @click="searchGplineClick">搜索</van-button>
                    </van-row>

                </template>
            </van-search>
        </van-row>
        <div style="height: 320px; overflow: scroll;padding: 10px 10px 60px 10px;">
            <van-row style="flex-wrap: wrap; margin: -8px;">
                <!-- 使用 flex-wrap 使子元素换行 -->
                <div v-for="(gpline, index) in filterGplineList" :key="index" class="custom-checkbox" :class="{ 'checked': gpline.checked }" @click="gplineCheck(index)" style="margin: 8px;">
                    {{ gpline.label }} <!-- 显示复选框的序号 -->
                </div>
            </van-row>
        </div>
        <van-row type="flex" class="bottom-row" justify="center" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
            <!-- <van-col span="5">已选择{{selectCount}}个</van-col>
                    <van-col span="16" style="text-align: right;">
                        <van-button plain size="small" type="danger" @click="resetCity">重 置</van-button>
                        <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveCity">确认选择</van-button>
                    </van-col> -->
            <van-button plain size="small" type="danger" @click="resetGpline">重 置</van-button>
        </van-row>
    </van-popup>

    <!--影院弹窗-->
    <van-popup round v-model="selectCinema" position="bottom">
        <!--弹框标题-->
        <div style="background-color: #f5f5f5;padding: 15px;">
            <van-row style="display: flex;justify-content: center;align-items: center;">
                <van-col span="24" style="text-align: center;">{{ selectCinemaTitle }}</van-col>
            </van-row>
        </div>
        <van-dropdown-menu>
            <van-dropdown-item :title="selectCinemaFilterCityText" ref="selectCinemafilterCity">
                <van-row>
                    <van-search style="width: 100%;display: flex;justify-content: center;align-items: center;" placeholder="请输入城市" v-model="searchText" :clearable=false>
                        <template #right-icon>
                            <van-row style="display: flex;justify-content: center;align-items: center;">
                                <van-icon v-if="searchText !== ''" name="clear" @click="clearCityClick" />
                                <van-button style="margin-left: 20px;" size="small" type="info" round=true @click="searchCityClick">搜索</van-button>
                            </van-row>

                        </template>
                    </van-search>
                </van-row>
                <van-tree-select :items="filterCityList" :main-active-index.sync="activeIndex" style="height: 320px;padding: 10px 10px 60px 10px;">
                    <template slot="content">
                        <van-row v-for="(city, index) in filterCityList[activeIndex].city_list" :key="index" style="background-color: white;margin-bottom: 10px;">
                            <van-cell :title="city.name" class="cell-with-margin">
                                <template #right-icon>
                                    <van-checkbox v-model="city.checked" shape="square" @click="toggleCity(index)"></van-checkbox>
                                </template>
                            </van-cell>
                        </van-row>

                    </template>
                </van-tree-select>
                <van-row type="flex" class="bottom-row" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
                    <van-col span="5">已选择{{selectCount}}个</van-col>
                    <van-col span="16" style="text-align: right;">
                        <van-button plain size="small" type="danger" @click="resetFilterCity">重 置</van-button>
                        <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveFiterCity">确认选择</van-button>
                    </van-col>
                </van-row>
            </van-dropdown-item>
            <van-dropdown-item ref="selectCinemafilterGpline" :title="selectCinemaFilterGplineText">
                <van-row>
                    <van-search style="width: 100%;display: flex;justify-content: center;align-items: center;" placeholder="请输入品牌名" v-model="searchText" :clearable=false>
                        <template #right-icon>
                            <van-row style="display: flex;justify-content: center;align-items: center;">
                                <van-icon v-if="searchText !== ''" name="clear" @click="clearGplineClick" />
                                <van-button style="margin-left: 20px;" size="small" type="info" round=true @click="searchGplineClick">搜索</van-button>
                            </van-row>

                        </template>
                    </van-search>
                </van-row>
                <div style="height: 320px; overflow: scroll;padding: 10px 10px 60px 10px;">

                    <van-row style="flex-wrap: wrap; margin: -6px;">

                        <div v-for="(gpline, index) in filterGplineList" :key="index" class="custom-checkbox" :class="{ 'checked': gpline.checked }" @click="selectCinemaFilterGplineCheck(index)" style="margin: 6px;font-size: small;">
                            {{ gpline.label }}
                        </div>
                    </van-row>
                </div>
                <van-row type="flex" class="bottom-row" justify="center" style="font-size: 12px;padding: 5px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
                    <!-- <van-col span="5">已选择{{selectCount}}个</van-col>
                    <van-col span="16" style="text-align: right;">
                        <van-button plain size="small" type="danger" @click="resetCity">重 置</van-button>
                        <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveCity">确认选择</van-button>
                    </van-col> -->
                    <van-button plain size="small" type="danger" @click="resetFilterGpline">重 置</van-button>
                </van-row>
            </van-dropdown-item>
        </van-dropdown-menu>

        <van-row>
            <van-search style="width: 100%;display: flex;justify-content: center;align-items: center;" placeholder="请输入影院名" v-model="searchCinemaText" :clearable=false>
                <template #right-icon>
                    <van-row style="display: flex;justify-content: center;align-items: center;">
                        <van-icon v-if="searchCinemaText !== ''" name="clear" @click="clearCinemaClick" />
                        <van-button style="margin-left: 20px;" size="small" type="info" round=true @click="searchCinemaClick">搜索</van-button>
                    </van-row>

                </template>
            </van-search>
        </van-row>
        <div style="height: 420px; overflow: scroll;padding: 10px 10px 60px 10px;">
            <van-list v-model="loading" :error.sync="error" error-text="请求失败，点击重新加载" :offset="50" @load="onLoad">
                <div v-for="(cinema, index) in filterCinemaList" :key="index" style="padding: 5px; font-size: small;">
                    <van-checkbox v-model="cinema.checked" @click="toggleCinema(index)">{{ cinema.city_name }} · {{ cinema.name }}</van-checkbox>
                </div>
                <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
            </van-list>
            <!-- <van-row style="flex-wrap: wrap; margin: -8px;">
            
                                <div v-for="(gpline, index) in filterGplineList" :key="index" class="custom-checkbox" :class="{ 'checked': gpline.checked }" @click="selectCinemaFilterGplineCheck(index)" style="margin: 8px;">
                                    {{ gpline.label }} 
                                </div>
                            </van-row> -->
        </div>
        <van-row type="flex" class="bottom-row" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
            <van-col span="5" @click="onCinemaClick">
                <div style="text-decoration: underline;cursor: pointer;color:blue">{{ selectCinemaText }}</div>
            </van-col>
            <!-- <van-dropdown-menu span="5" direction="up" :title="selectCinemaText">
                        <van-dropdown-item v-model="value1" :options="option1" />
                    </van-dropdown-menu> -->
            <van-col span="16" style="text-align: right;">
                <van-button plain size="small" type="danger" @click="resetCinema">重 置</van-button>
                <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveCinema">确认选择</van-button>
            </van-col>
        </van-row>
    </van-popup>

    <van-action-sheet v-model="showSelectCinema" :actions="selectCinemaList" description="已选中的影院" />

    <!--影厅弹窗-->
    <van-popup v-model="selectHall" round position="bottom">
        <!--弹框标题-->
        <div style="background-color: #f5f5f5;padding: 10px;">
            <van-row style="display: flex;justify-content: center;align-items: center;">
                <van-col span="24" style="text-align: center;">{{ selectHallTitle }}</van-col>
            </van-row>
        </div>
        <van-row>
            <van-search style="width: 100%;display: flex;justify-content: center;align-items: center;" placeholder="请输入影厅名" v-model="searchText" :clearable=false>
                <template #right-icon>
                    <van-row style="display: flex;justify-content: center;align-items: center;">
                        <van-icon v-if="searchText !== ''" name="clear" @click="clearHallClick" />
                        <van-button style="margin-left: 20px;" size="small" type="info" round=true @click="searchHallClick">搜索</van-button>
                    </van-row>

                </template>
            </van-search>
        </van-row>
        <div style="height: 320px; overflow: scroll;padding: 10px 10px 60px 10px;">
            <van-row style="flex-wrap: wrap; margin: -6px;">
                <!-- 使用 flex-wrap 使子元素换行 -->
                <div v-for="(hall, index) in filterHallList" :key="index" class="custom-checkbox" :class="{ 'checked': hall.checked }" @click="hallCheck(index)" style="margin: 6px;font-size: small;">
                    {{ hall.label }} <!-- 显示复选框的序号 -->
                </div>
            </van-row>
        </div>
        <van-row type="flex" class="bottom-row" justify="space-between" style="font-size: 12px;padding: 5px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
            <van-col span="5">已选择{{selectCount}}个</van-col>
            <van-col span="16" style="text-align: right;">
                <van-button plain size="small" type="danger" @click="resetHall">重 置</van-button>
                <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveHall">确认选择</van-button>
            </van-col>
        </van-row>
    </van-popup>

    <!--影片弹窗-->
    <van-popup v-model="selectMovie" round position="bottom">
        <!--弹框标题-->
        <div style="background-color: #f5f5f5;padding: 15px;">
            <van-row style="display: flex;justify-content: center;align-items: center;">
                <van-col span="24" style="text-align: center;">{{ selectMovieTitle }}</van-col>
            </van-row>
        </div>
        <van-row>
            <van-search style="width: 100%;display: flex;justify-content: center;align-items: center;" placeholder="请输入影片名" v-model="searchText" :clearable=false>
                <template #right-icon>
                    <van-row style="display: flex;justify-content: center;align-items: center;">
                        <van-icon v-if="searchText !== ''" name="clear" @click="clearMovieClick" />
                        <van-button style="margin-left: 20px;" size="small" type="info" round=true @click="searchMovieClick">搜索</van-button>
                    </van-row>

                </template>
            </van-search>
        </van-row>
        <div style="height: 420px; overflow: scroll;padding: 10px 10px 60px 10px;">
            <van-row style="flex-wrap: wrap; margin: -8px;">
                <!-- 使用 flex-wrap 使子元素换行 -->
                <div v-for="(movie, index) in filterMovieList" :key="index" class="custom-checkbox" :class="{ 'checked': movie.checked }" @click="movieCheck(index)" style="margin: 8px;font-size: small;">
                    {{ movie.label }} <!-- 显示复选框的序号 -->
                </div>
            </van-row>
        </div>
        <van-row type="flex" class="bottom-row" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
            <van-col span="5">已选择{{selectCount}}个</van-col>
            <van-col span="16" style="text-align: right;">
                <van-button plain size="small" type="danger" @click="resetMovie">重 置</van-button>
                <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveMovie">确认选择</van-button>
            </van-col>
        </van-row>
    </van-popup>
    <van-popup v-model="selectTime" round position="bottom">
        <van-datetime-picker style="height: 320px; overflow: scroll;padding: 10px 10px 15px 10px;" v-model="currentTime" type="time" :min-hour="0" :max-hour="23" @confirm="timeCheck" @cancel="selectTime = false" />
    </van-popup>

    <van-popup v-model="selectDate" round position="bottom">
        <van-datetime-picker style="height: 320px; overflow: scroll;padding: 10px 10px 15px 10px;" v-model="currentDate" type="date" @confirm="dateCheck" @cancel="selectData = false" />
    </van-popup>

    <!--星期弹窗-->
    <van-popup v-model="selectWeek" round position="bottom">
        <!--弹框标题-->
        <div style="background-color: #f5f5f5;padding: 15px;">
            <van-row style="display: flex;justify-content: center;align-items: center;">
                <van-col span="24" style="text-align: center;">添加包含星期</van-col>
            </van-row>
        </div>
        <div style="height: 200px; overflow: scroll;padding: 10px 10px 15px 10px;">
            <van-row style="flex-wrap: wrap; margin: -8px;">
                <!-- 使用 flex-wrap 使子元素换行 -->
                <div v-for="(week, index) in filterWeekList" :key="index" class="custom-checkbox" :class="{ 'checked': week.checked }" @click="weekCheck(index)" style="margin: 8px;font-size: small;">
                    {{ week.name }} <!-- 显示复选框的序号 -->
                </div>
            </van-row>
        </div>
        <van-row type="flex" class="bottom-row" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;">
            <van-col span="5">已选择{{selectCount}}个</van-col>
            <van-col span="16" style="text-align: right;">
                <van-button plain size="small" type="danger" @click="resetWeek">重 置</van-button>
                <van-button type="danger" size="small" style="margin-left: 40px;" @click="saveWeek">确认选择</van-button>
            </van-col>
        </van-row>
    </van-popup>

</div>
</template>

<script>
import Api from '@/api-service'

import {
    Dialog
} from 'vant-green';

export default {
    name: "Rule-Custom",
    data() {
        return {
            configId: 0,
            configType: 4,
            copy: 0,

            ruleInfo: {
                include_province_desc: '设置包含省',
                include_province_scope: [],

                exclude_province_desc: '设置不包含省',
                exclude_province_scope: [],

                include_city_desc: '设置包含城市',
                include_city_scope: [],

                exclude_city_desc: '设置不包含城市',
                exclude_city_scope: [],
                gp_line: 0,
                line_name: '请选择包含品牌',

                include_cinema_desc: '设置包含影院',
                include_cinema_scope: [],

                exclude_cinema_desc: '设置不包含影院',
                exclude_cinema_scope: [],
                cinema_desc: '',

                hall_name: [],
                include_hall_desc: '设置包含影厅',
                include_hall_scope: [],
                exclude_hall_desc: '设置不包含影厅',
                exclude_hall_scope: [],

                include_movie_desc: '设置包含影片',
                include_movie_scope: [],
                exclude_movie_desc: '设置不包含影片',
                exclude_movie_scope: [],

                begin_price: '',
                end_price: '',

                time_scope: [],
                begin_time: '',
                end_time: '',

                date_scope: [],
                begin_date: '',
                end_date: '',

                week_desc: '设置包含星期',
                week_days: [],

                ticket_num: [0],

                // 报价类型
                bid_type: 6,
                bid_value: '',

                status: 1,
            },

            bid_type_desc: {
                3: '订单金额的百分比折扣，如填90，则为单价的9折进行自动报价',
                4: '当建议顶价为40时，如填2，则使用38自动报价，支持1位小数',
                5: '如填38，则使用38自动报价，支持1位小数',
                6: '当单价原价为40时，如填2，则使用38自动报价，支持1位小数',
            },

            selectProvince: false,
            selectCity: false,
            selectGpline: false,
            selectCinema: false,

            activeIndex: 0,
            provinceList: [],
            filterProvinceList: [],
            selectProvinceList: [],

            cityList: [],
            filterCityList: [],
            selectCityList: [],

            gpLineList: [],
            filterGplineList: [],
            selectedGpline: 0,

            // searchGplineList: [], // 搜索时，保存的gpLineList

            searchText: '',
            selectCount: 0,
            src: 0,

            selectCinemaFilterCityNameList: [],
            // selectCinemaFilterCount: 0,
            selectCinemaFilterGplineList: [],
            selectCinemaFilterGplineCount: 0,

            searchCinemaText: '',
            cinemaList: [],
            filterCinemaList: [],
            selectCinemaList: [],
            selectCinemaNameList: [],
            selectCinemaCount: 0,
            page: 1,
            pageSize: 20,
            loading: false,
            error: false,
            finished: false,
            showSelectCinema: false,

            // selectCinemaFilterCityList: [],

            // selectCinemaCityList: [],
            selectCinemaCityCount: 0,

            selectHall: false,
            defaultHallList: [],
            selectHallList: [],
            filterHallList: [],

            // 影片
            selectMovie: false,
            movieList: [],
            selectMovieList: [],
            filterMovieList: [],

            // 时间
            selectTime: false,
            currentTime: '',

            // 日期
            selectDate: false,
            currentDate: '',

            // 星期
            selectWeek: false,
            weekList: [{
                    label: '星期一',
                    value: 1
                },
                {
                    label: '星期二',
                    value: 2
                },
                {
                    label: '星期三',
                    value: 3
                },
                {
                    label: '星期四',
                    value: 4
                },
                {
                    label: '星期五',
                    value: 5
                },
                {
                    label: '星期六',
                    value: 6
                },
                {
                    label: '星期日',
                    value: 7
                }
            ],
            selectWeekList: [],
            filterWeekList: [],

        }
    },
    computed: {
        selectCinemaFilterCityText() {
            return `城市(${this.selectCinemaCityCount})`
        },
        selectCinemaFilterGplineText() {
            return `品牌(${this.selectCinemaFilterGplineCount})`
        },
        selectCinemaText() {
            return `已选择 ${this.selectCinemaCount} 个`
        },
        selectCinemaTitle() {
            if (this.src === 1) {
                return '添加包含影院'
            } else {
                return '添加不包含影院'
            }
        },
        selectHallTitle() {
            if (this.src === 1) {
                return '添加包含影厅'
            } else {
                return '添加不包含影厅'
            }
        },
        selectMovieTitle() {
            if (this.src === 1) {
                return '添加包含影片'
            } else {
                return '添加不包含影片'
            }
        },
        priceText(src) {
            if (src == 1) {
                if (this.ruleInfo.begin_price == '') {
                    return '最低'
                } else {
                    return this.ruleInfo.begin_price
                }
            } else {
                if (this.ruleInfo.end_price == '') {
                    return '最高'
                } else {
                    return this.ruleInfo.end_price
                }
            }
        },
        timeText(src) {
            if (src == 1) {
                if (this.ruleInfo.begin_price == '') {
                    return '开始'
                } else {
                    return this.ruleInfo.begin_price
                }
            } else {
                if (this.ruleInfo.end_price == '') {
                    return '结束'
                } else {
                    return this.ruleInfo.end_price
                }
            }
        }
    },
    created() {
        this.configId = this.$route.query.id;
        this.configType = this.$route.query.type;
        this.copy = this.$route.query.copy;

        console.log(this.configId);
        if (this.configId) {
            this.fetchData();
        }

        this.getProvince()
        this.getGpline()

        this.getCinema({
            page: this.page,
            pageSize: this.pageSize,
        })

        this.getHallList()
        this.getMovieList()
    },
    methods: {
        fetchData() {
            Api.getConfigDetail({
                id: this.configId
            }).then(res => {
                this.ruleInfo = res
                if (this.ruleInfo.include_province_scope !== null && this.ruleInfo.include_province_scope.length > 0) {
                    this.ruleInfo.include_province_desc = this.ruleInfo.province_desc
                } else {
                    this.ruleInfo.include_province_desc = '设置包含省'
                }

                if (this.ruleInfo.exclude_province_scope !== null && this.ruleInfo.exclude_province_scope.length > 0) {
                    this.ruleInfo.exclude_province_desc = this.ruleInfo.province_desc
                } else {
                    this.ruleInfo.exclude_province_desc = '设置不包含省'
                }

                // 城市
                if (this.ruleInfo.include_city_scope !== null && this.ruleInfo.include_city_scope.length > 0) {
                    this.ruleInfo.include_city_desc = this.ruleInfo.city_desc
                } else {
                    this.ruleInfo.include_city_desc = '设置包含城市'
                }

                if (this.ruleInfo.exclude_city_scope !== null && this.ruleInfo.exclude_city_scope.length > 0) {
                    this.ruleInfo.exclude_city_desc = this.ruleInfo.city_desc
                } else {
                    this.ruleInfo.exclude_city_desc = '设置不包含城市'
                }

                if (this.ruleInfo.hasOwnProperty('include_hall_scope') && this.ruleInfo['include_hall_scope'] !== undefined && this.ruleInfo.include_hall_scope !== null && this.ruleInfo.include_hall_scope.length > 0) {
                    this.ruleInfo.include_hall_desc = this.ruleInfo.include_hall_scope.join('、')
                } else {
                    this.ruleInfo.include_hall_desc = '设置包含影厅'
                }

                if (this.ruleInfo.hasOwnProperty('hall_name') && this.ruleInfo['hall_name'] !== undefined && this.ruleInfo.hall_name !== null && this.ruleInfo.hall_name.length > 0) {
                    this.ruleInfo.exclude_hall_desc = this.ruleInfo.hall_name.join('、')
                } else {
                    this.ruleInfo.exclude_hall_desc = '设置不包含影厅'
                }

                // 影院
                if (this.ruleInfo.include_cinema_scope !== null && this.ruleInfo.include_cinema_scope.length > 0) {
                    this.ruleInfo.include_cinema_desc = '包含' + this.ruleInfo.include_cinema_scope.length + '个影院'
                } else {
                    this.ruleInfo.include_cinema_desc = '设置包含影院'
                }

                if (this.ruleInfo.exclude_cinema_scope !== null && this.ruleInfo.exclude_cinema_scope.length > 0) {
                    this.ruleInfo.exclude_cinema_desc = '包含' + this.ruleInfo.exclude_cinema_scope.length + '个影院'
                } else {
                    this.ruleInfo.exclude_cinema_desc = '设置不包含影院'
                }

                // 影片
                if (this.ruleInfo.include_movie_scope !== null && this.ruleInfo.include_movie_scope.length > 0) {
                    this.ruleInfo.include_movie_desc = this.ruleInfo.movie_desc
                } else {
                    this.ruleInfo.include_movie_desc = '设置包含影片'
                }

                if (this.ruleInfo.exclude_movie_scope !== null && this.ruleInfo.exclude_movie_scope.length > 0) {
                    this.ruleInfo.exclude_movie_desc = this.ruleInfo.movie_desc
                } else {
                    this.ruleInfo.exclude_movie_desc = '设置不包含影片'
                }

                // 星期
                if (this.ruleInfo.week_days !== null && this.ruleInfo.week_days.length > 0) {
                    this.ruleInfo.week_desc = this.ruleInfo.week_days.join('、')
                } else {
                    this.ruleInfo.week_desc = '设置包含星期'
                }

                this.ruleInfo.bid_type = parseInt(this.ruleInfo.bid_type, 10);

                if (this.ruleInfo.line_name === '-') {
                    this.ruleInfo.line_name = '请选择包含品牌'
                }

                console.log(this.ruleInfo)
                if (this.copy) {
                    // 名字加上1。id置空
                    this.ruleInfo.name += (复制)
                    this.configId = 0
                }

            })
        },
        getProvince() {
            Api.getProvinceCityList({}).then(res => {
                this.provinceList = res.list.map(el => {
                    return {
                        label: el.name,
                        value: el.id,
                        checked: false,
                    }
                })
                this.cityList = res.list
                this.filterCityList = res.list
            })
        },
        getGpline() {
            Api.getGpLineList({}).then(res => {
                this.gpLineList = res.list.map(el => {
                    return {
                        label: el.name,
                        value: el.ID,
                        checked: false,
                    }
                })
            })
        },
        getCinema(data) {
            console.log(data)
            Api.getCinemaList(data).then(res => {
                this.cinemaList = res.list.map(el => {
                    return {
                        name: el.name,
                        city_name: el.city_name,
                        id: el.ID,
                        checked: false,
                    }
                })
            })
        },
        getHallList(data) {
            Api.getDefaultHallList(data).then(res => {
                this.defaultHallList = res.map(el => {
                    return {
                        name: el.name,
                    }
                })
            })
        },
        getMovieList() {
            const data = {
                'pageSize': 100,
                'page': 1,
                'orderKey': 'created_at',
                'desc': true
            }
            Api.getMovieList(data).then(res => {
                this.movieList = res.list.map(el => {
                    return {
                        name: el.name,
                        id: el.ID,
                        checked: false,
                    }
                })
            })
        },
        setStatus(checked) {
            if (checked) {
                this.ruleInfo.status = 1;
            } else {
                this.ruleInfo.status = 2;
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        deleteRule() {
            Dialog.confirm({
                    message: '确认删除报价方案？'
                })
                .then(() => {
                    Api.deleteConfig({
                        id: this.ruleInfo.id
                    }).then(res => {
                        this.goBack()
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
        showProvinceSelect(src) {
            this.selectProvince = true;
            this.src = src;
            this.selectCount = 0;
            this.selectProvinceList = []
            this.filterProvinceList = []

            let curProvinceList = [];
            if (src === 1) {
                // 选择省份，遍历省份filterProvinceList，将选择的省份checked=true
                curProvinceList = this.ruleInfo.include_province_scope

                // this.filterProvinceList.forEach(item => {
                //     // item.value是否在this.ruleInfo.include_province_scope中，
                //     console.log(this.selectProvinceList)
                //     if (this.selectProvinceList.indexOf(item.value) != -1) {
                //         item.checked = true;
                //         console.log('not in')
                //         this.selectCount++;
                //     } else {
                //         item.checked = false;
                //     }
                //     /*
                //     if (this.selectProvinces.indexOf(item.value) != -1 && item.checked) {
                //         item.checked = true;
                //         this.selectCount++;
                //     } else {
                //         item.checked = false;
                //     }
                //     */
                // })
            } else {
                curProvinceList = this.ruleInfo.exclude_province_scope
                // this.filterProvinceList.forEach(item => {
                //     // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
                //     if (this.ruleInfo.exclude_province_scope.indexOf(item.value) != -1) {
                //         item.checked = true;
                //         this.selectCount++;
                //     } else {
                //         console.log('not in')
                //         item.checked = false;
                //     }
                // })
            }
            console.log(curProvinceList)
            console.log(this.provinceList)
            this.provinceList.forEach(item => {
                // item.value是否在this.ruleInfo.include_province_scope中，
                let checked = false
                if (curProvinceList != null && curProvinceList.indexOf(item.value) != -1) {
                    checked = true;
                    console.log('not in')
                    this.selectCount++;
                    this.selectProvinceList.push({
                        label: item.label,
                        value: item.value,
                    })
                }

                this.filterProvinceList.push({
                    label: item.label,
                    value: item.value,
                    checked: checked,
                })
                /*
                if (this.selectProvinces.indexOf(item.value) != -1 && item.checked) {
                    item.checked = true;
                    this.selectCount++;
                } else {
                    item.checked = false;
                }
                */
            })
            console.log(this.filterProvinceList)
        },
        provinceCheck(index) {
            // console.log(this.filterProvinceList[index].checked)
            this.filterProvinceList[index].checked = !this.filterProvinceList[index].checked;
            if (this.filterProvinceList[index].checked) {
                this.selectProvinceList.push(this.filterProvinceList[index])
                this.selectCount++
            } else {
                this.selectProvinceList.forEach((item, index1) => {
                    if (item.value === this.filterProvinceList[index].value) {
                        this.selectProvinceList.splice(index1, 1)
                    }
                })
                this.selectCount--
            }

        },
        resetProvince() {
            this.filterProvinceList.forEach(item => {
                // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
                item.checked = false;
            })
            this.selectProvinceList = []
            this.selectCount = 0
        },
        searchProvinceClick() {
            if (this.searchText) {
                this.filterProvinceList.filter(item => {
                    return item.label.indexOf(this.searchText) != -1
                })

            }
        },
        saveProvince() {
            // let data = []
            // this.filterProvinceList.forEach(item => {
            //     // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
            //     if (item.checked) {
            //         data.push(item)
            //     }
            // })

            // 遍历data，将选择的省份名保存在include_province_desc和exclude_province_desc
            let provinceName = ''
            let provinceId = []
            this.selectProvinceList.forEach(item => {
                provinceName += item.label + ','
                provinceId.push(item.value)
            })

            if (provinceName === '') {
                if (this.src == 1) {
                    provinceName = '设置包含省'
                } else {
                    provinceName = '设置不包含省'
                }

            }
            this.ruleInfo[this.src == 1 ? 'include_province_desc' : 'exclude_province_desc'] = provinceName
            this.ruleInfo[this.src == 1 ? 'include_province_scope' : 'exclude_province_scope'] = provinceId

            this.selectProvince = false
        },
        clearProvinceClick() {
            // 清除省份搜索
            this.searchText = '';
            // this.filterGplineList = this.gplineList;
            this.filterGplineList = []

            this.provinceList.forEach(item => {
                // item.value是否在this.ruleInfo.include_province_scope中，
                let checked = false
                // 判断是否在选中省份中

                this.selectProvinceList.forEach(item2 => {
                    if (item2.value === item.value) {
                        checked = true;
                        return
                    }
                })

                this.filterProvinceList.push({
                    label: item.label,
                    value: item.value,
                    checked: checked,
                })
            })
        },

        showCitySelect(src, show) {
            console.log('showCitySelect')
            this.src = src;
            this.selectCount = 0;
            this.selectCityList = []
            this.filterCityList = []

            let curCityList = [];

            if (show) {

                if (src === 1) {
                    curCityList = this.ruleInfo.include_city_scope

                } else {
                    curCityList = this.ruleInfo.exclude_city_scope
                }
            }

            console.log(curCityList)
            console.log(this.cityList)
            this.cityList.forEach((item, index) => {
                let info = ''
                let arrCity = []
                item.city_list.forEach(itemCity => {
                    // item.value是否在this.ruleInfo.exclude_city_scope中，
                    // console.log(itemCity.ID, curCityList)
                    let checked = false
                    // console.log(checked)
                    if (curCityList != null) {
                        curCityList.forEach(item1 => {
                            console.log(item1, itemCity.ID)
                            if (item1 === itemCity.city_code) {
                                checked = true;
                                this.selectCount++;
                                if (info == '') {
                                    info = 1
                                } else {
                                    info++
                                }
                                if (this.activeIndex == 0) {
                                    this.activeIndex = index
                                }
                                return
                            }
                        })
                    }

                    if (checked) {
                        this.selectCityList.push({
                            name: itemCity.name,
                            id: itemCity.ID,
                            city_code: itemCity.city_code
                        })
                    }
                    arrCity.push({
                        name: itemCity.name,
                        id: itemCity.ID,
                        city_code: itemCity.city_code,
                        checked: checked
                    })
                })

                this.filterCityList.push({
                    text: item.name,
                    info: info,
                    city_list: arrCity
                })
            })

            console.log(this.filterCityList)
            this.selectCity = show;

        },
        toggleCity(index) {

            // if (this.filterCityList[this.activeIndex].city_list[index].checked) {
            //     console.log('not in222')
            //     this.filterCityList[this.activeIndex].city_list[index].checked = false
            // }else {
            //     console.log('not in')
            //     this.filterCityList[this.activeIndex].city_list[index].checked = true
            // }
            // this.filterCityList[this.activeIndex].city_list[index].checked = !this.filterCityList[this.activeIndex].city_list[index].checked;
            console.log(this.filterCityList[this.activeIndex].city_list[index])
            if (this.filterCityList[this.activeIndex].city_list[index].checked) {
                this.selectCount++
                if (this.filterCityList[this.activeIndex].info == '') {
                    this.filterCityList[this.activeIndex].info = 1
                } else {
                    this.filterCityList[this.activeIndex].info++
                }

                // 添加选中的城市
                this.selectCityList.push({
                    name: this.filterCityList[this.activeIndex].city_list[index].name,
                    id: this.filterCityList[this.activeIndex].city_list[index].id,
                    city_code: this.filterCityList[this.activeIndex].city_list[index].city_code
                })

            } else {
                this.selectCount--
                this.filterCityList[this.activeIndex].info--

                // 删除已选
                for (let i = 0; i < this.selectCityList.length; i++) {
                    if (this.selectCityList[i].id == this.filterCityList[this.activeIndex].city_list[index].id) {
                        this.selectCityList.splice(i, 1)
                        break
                    }
                }
            }
        },
        resetCity() {
            this.filterCityList.forEach(item => {
                // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
                item.info = '';
                item.city_list.forEach(item => {
                    item.checked = false;
                })
            })
            this.selectCount = 0
            this.selectCityList = []
        },
        saveCity() {
            // let data = []
            // this.filterCityList.forEach(item => {
            //     // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
            //     item.city_list.forEach(item => {
            //         if (item.checked) {
            //             data.push(item)
            //         }

            //     })
            // })

            // 遍历data，将选择的省份名保存在include_province_desc和exclude_province_desc
            let cityName = ''
            let cityId = []
            this.selectCityList.forEach(item => {
                cityName += item.name + ','
                cityId.push(item.city_code)
            })
            console.log(cityId)
            console.log(this.selectCityList)
            if (cityName === '') {
                if (this.src == 1) {
                    cityName = '设置包含城市'
                } else {
                    cityName = '设置不包含城市'
                }

            }
            this.ruleInfo[this.src == 1 ? 'include_city_desc' : 'exclude_city_desc'] = cityName
            this.ruleInfo[this.src == 1 ? 'include_city_scope' : 'exclude_city_scope'] = cityId

            this.selectCity = false
        },
        clearCityClick() {
            this.searchText = '';
            // this.filterGplineList = this.gplineList;
            this.filterCityList = []
            this.activeIndex == 0
            this.selectCount = 0

            this.cityList.forEach((item, index) => {
                let info = ''
                let arrCity = []
                item.city_list.forEach(itemCity => {
                    // item.value是否在this.ruleInfo.exclude_city_scope中，
                    console.log(itemCity.ID, this.selectCityList)
                    let checked = false
                    console.log(checked)
                    this.selectCityList.forEach(item1 => {
                        console.log(item1, itemCity.ID)
                        if (item1.id === itemCity.ID) {
                            checked = true;
                            this.selectCount++;
                            if (info == '') {
                                info = 1
                            } else {
                                info++
                            }
                            if (this.activeIndex == 0) {
                                this.activeIndex = index
                            }
                            return
                        }
                    })

                    arrCity.push({
                        name: itemCity.name,
                        id: itemCity.ID,
                        city_code: itemCity.city_code,
                        checked: checked
                    })
                })

                this.filterCityList.push({
                    text: item.name,
                    info: info,
                    city_list: arrCity
                })
            })
        },
        searchCityClick() {
            console.log(this.searchText)
            if (this.searchText) {
                // this.selectCity = false
                this.filterCityList = []
                this.activeIndex == 0
                this.cityList.forEach((item, index) => {
                    let info = ''
                    let arrCity = []
                    let checked = false
                    item.city_list.forEach(itemCity => {
                        // item.value是否在this.ruleInfo.exclude_city_scope中，
                        // console.log(this.searchText, itemCity.name)

                        // console.log(checked)
                        if (itemCity.name.indexOf(this.searchText) !== -1) {
                            checked = true;
                            if (info == '') {
                                info = 1
                            } else {
                                info++
                            }
                            if (this.activeIndex == 0) {
                                this.activeIndex = index
                            }
                        }

                        arrCity.push({
                            name: itemCity.name,
                            id: itemCity.ID,
                            checked: false,
                            city_code: itemCity.city_code
                        })
                        // if (checked) {
                        //     arrCity.push({
                        //         name: itemCity.name,
                        //         id: itemCity.ID,
                        //         checked: false,
                        //         city_code: itemCity.city_code
                        //     })
                        // }else {
                        //     arrCity.push({
                        //         name: itemCity.name,
                        //         id: itemCity.ID,
                        //         checked: false,
                        //         city_code: itemCity.city_code
                        //     })
                        // }

                    })
                    // console.log(arrCity)
                    // if (arrCity.length > 0) {
                    this.filterCityList.push({
                        text: item.name,
                        // info: info,
                        city_list: arrCity,
                        dot: checked ? true : false

                    })
                    // }
                    // return

                })
                this.filterCityList.sort((a, b) => b.dot - a.dot)
                this.activeIndex = 0
                console.log(this.filterCityList)
                // this.selectCity = true
            }
        },

        showGplineSelect(show) {
            console.log("showGplineSelect")
            this.searchText = ''
            // this.searchGplineList = []
            this.filterGplineList = []

            if (show) {
                this.selectedGpline = this.ruleInfo.gp_line
            }
            console.log(this.gpLineList)
            this.gpLineList.forEach(item => {
                let checked = false
                if (this.selectedGpline === item.value) {
                    checked = true;
                }
                this.filterGplineList.push({
                    label: item.label,
                    value: item.value,
                    checked: checked
                })
            })
            console.log(this.filterGplineList)
            this.selectGpline = show
        },
        gplineCheck(index) {
            this.ruleInfo.gp_line = this.filterGplineList[index].value;
            this.ruleInfo.line_name = this.filterGplineList[index].label;

            this.selectGpline = false;
        },
        searchGplineClick() {
            // this.searchGolineText = '';
            // this.filterGplineList = this.gplineList;
            if (this.searchText) {
                this.filterGplineList = []

                this.gpLineList.forEach(item => {
                    if (item.label.indexOf(this.searchText) !== -1) {
                        let checked = false
                        if (this.ruleInfo.gp_line === item.value) {
                            checked = true;
                        }
                        this.filterGplineList.push({
                            label: item.label,
                            value: item.value,
                            checked: checked
                        })
                    }

                })
                console.log(this.filterGplineList)
            }

        },
        clearGplineClick() {
            this.searchText = '';
            // this.filterGplineList = this.gplineList;
            this.filterGplineList = []

            this.gpLineList.forEach(item => {
                let checked = false
                if (this.ruleInfo.gp_line === item.value) {
                    checked = true;
                }
                this.filterGplineList.push({
                    label: item.label,
                    value: item.value,
                    checked: checked
                })
            })
        },
        resetGpline() {
            this.ruleInfo.gp_line = 0;
            this.ruleInfo.line_name = '请选择包含品牌';

            this.selectGpline = false;
        },
        showCinemaSelect(src) {
            console.log('showCinemaSelect')
            this.src = src;
            this.selectCount = 0;
            this.selectCinemaCount = 0;
            this.selectedGpline = 0;
            this.searchCinemaText = '';
            this.selectCinemaList = this.ruleInfo.select_cinema;
            this.filterCinemaList = []

            let curCinemaList = [];
            if (src === 1) {
                curCinemaList = this.ruleInfo.include_cinema_scope;
            } else {
                curCinemaList = this.ruleInfo.exclude_cinema_scope;
            }
            console.log(this.selectCinemaList)

            if (curCinemaList != null) {
                this.selectCinemaCount = curCinemaList.length
            }

            console.log(curCinemaList)

            // this.selectCinemaList = curCinemaList
            // if (this.ruleInfo.cinema_desc != '') {
            //     // 分割,
            //     this.selectCinemaNameList = this.ruleInfo.cinema_desc.split(',')
            // }
            // this.selectCinemaCount = this.selectCinemaNameList.length

            // 处理列表中的影院
            this.cinemaList.forEach(item => {
                let checked = false
                if (curCinemaList != null && curCinemaList.indexOf(item.id) != -1) {
                    checked = true;
                }

                // if (checked) {
                //     this.selectCinemaCount++
                //     this.selectCinemaList.push({
                //         name: item.name,
                //         city_name: item.city_name,
                //         id: item.id,
                //     })
                // }
                this.filterCinemaList.push({
                    name: item.name,
                    city_name: item.city_name,
                    id: item.id,
                    checked: checked,
                })
            })
            console.log(this.filterCinemaList)
            this.showCitySelect(src, false)
            this.showGplineSelect(false)

            // 判断filterCinemaList中是否有selectCinemaList的影院，如果有checked设置true
            if (this.selectCinemaList != null) {
                this.selectCinemaList.forEach(item => {
                    let checked = false
                    this.filterCinemaList.forEach(item2 => {
                        if (item.id === item2.id) {
                            checked = true;
                            // this.selectCinemaCount++
                        }
                    })
                    if (!checked) {
                        // 添加到filterCinemaList的第一个位置
                        this.filterCinemaList.unshift({
                            name: item.name,
                            city_name: item.city_name,
                            id: item.id,
                            checked: true,
                        })
                    }

                })
            }

            console.log(this.filterCinemaList)
            this.selectCinema = true;
        },
        resetFilterCity() {
            // 筛选城市，保存
            this.resetCity()
            this.selectCinemaCityCount = this.selectCount;
        },
        saveFiterCity() {
            console.log('saveFiterCity')
            this.selectCinemaCityCount = this.selectCount;
            this.$refs.selectCinemafilterCity.toggle(false)
            // 保存筛选的城市，并重新查询影院列表
            this.getCinemaByFitler(true)
            this.loading = false

        },
        selectCinemaFilterGplineCheck(index) {

            console.log(this.filterGplineList[index])
            this.filterGplineList.forEach(item => {
                item.checked = false
            })
            this.filterGplineList[index].checked = true
            this.selectCinemaFilterGplineCount = 1
            this.$refs.selectCinemafilterGpline.toggle(false)
            this.selectCity = false
            this.selectGpline = false;
            this.selectedGpline = this.filterGplineList[index].value;
            // 保存筛选品牌，并重新查询影院列表
            // this.ruleInfo.gp_line = this.filterGplineList[index].value;
            // this.ruleInfo.line_name = this.filterGplineList[index].label;
            this.getCinemaByFitler(true)
            this.loading = false
        },
        // filterGplineCheck(index) {
        //     this.selectedGpline = this.filterGplineList[index].value;

        //     this.selectGpline = false;
        // },
        resetFilterGpline() {
            this.selectedGpline = 0;
            this.selectCinemaFilterGplineCount = 0;
            this.filterGplineList.forEach(item => {
                item.checked = false
            })
            this.$refs.selectCinemafilterGpline.toggle(false)
            this.selectGpline = false;
            this.getCinemaByFitler(true)
        },
        clearCinemaClick() {
            // 清除搜索
            this.searchCinemaText = ''
            this.getCinemaByFitler(true)
        },
        searchCinemaClick() {
            this.getCinemaByFitler(true)
        },
        getCinemaByFitler(refresh) {
            let cityId = ''
            this.selectCityList.forEach(item => {
                // cityName += item.name + ','
                if (cityId !== '') {
                    cityId += ','
                }
                cityId += item.city_code
            })
            if (refresh) {
                this.page = 1
            } else {
                this.page++
            }
            let data = {
                page: this.page,
                pageSize: this.pageSize,
                // city_codes: cityId,
                line_id: this.selectedGpline,
                name: this.searchCinemaText
            }

            if (cityId.length > 0) {
                data.city_codes = cityId
            }

            Api.getCinemaList(data).then(res => {
                this.filterCinemaList = []

                let list = res.list.map(el => {
                    return {
                        name: el.name,
                        city_name: el.city_name,
                        id: el.ID,
                        checked: false,
                    }
                })

                if (refresh) {
                    this.cinemaList = list
                } else {
                    this.cinemaList = this.cinemaList.concat(list)
                }

                if (this.cinemaList.length >= res.total) {
                    this.finished = true
                }

                console.log(this.cinemaList)
                console.log(this.selectCinemaList)

                // 处理列表中的影院
                this.cinemaList.forEach(item => {
                    let checked = false
                    if (this.selectCinemaList != null) {
                        this.selectCinemaList.forEach(item2 => {
                            if (item2.id == item.id) {
                                checked = true;
                                return
                            }
                        })
                    }

                    this.filterCinemaList.push({
                        name: item.name,
                        city_name: item.city_name,
                        id: item.id,
                        checked: checked,
                    })
                })

                // 判断filterCinemaList中是否有selectCinemaList的影院，如果有checked设置true
                if (this.selectCinemaList != null) {
                    this.selectCinemaList.forEach(item => {
                        let checked = false
                        this.filterCinemaList.forEach(item2 => {
                            if (item.id === item2.id) {
                                checked = true;
                                return
                                // this.selectCinemaCount++
                            }
                        })
                        if (!checked) {
                            // 添加到filterCinemaList的第一个位置
                            this.filterCinemaList.unshift({
                                name: item.name,
                                city_name: item.city_name,
                                id: item.id,
                                checked: true,
                            })
                        }

                    })
                }

                console.log(this.filterCinemaList)

            })

        },
        onLoad() {
            console.log('onLoad')
            this.getCinemaByFitler(false)
            this.loading = false
        },
        toggleCinema(index) {
            // 勾选影院
            if(this.selectCinemaList == null) {
                this.selectCinemaList = []
            }
            console.log(this.selectCinemaList)

            // if (this.filterCityList[this.activeIndex].city_list[index].checked) {
            //     console.log('not in222')
            //     this.filterCityList[this.activeIndex].city_list[index].checked = false
            // }else {
            //     console.log('not in')
            //     this.filterCityList[this.activeIndex].city_list[index].checked = true
            // }
            // this.filterCityList[this.activeIndex].city_list[index].checked = !this.filterCityList[this.activeIndex].city_list[index].checked;
            console.log(this.filterCinemaList[index])
            if (this.filterCinemaList[index].checked) {
                // this.selectCinemaCount++

                // 添加选中的城市
                this.selectCinemaList.push({
                    name: this.filterCinemaList[index].name,
                    id: this.filterCinemaList[index].id,
                    city_name: this.filterCinemaList[index].city_name,
                })

            } else {

                // 删除已选
                for (let i = 0; i < this.selectCinemaList.length; i++) {
                    if (this.selectCinemaList[i].id == this.filterCinemaList[index].id) {
                        this.selectCinemaList.splice(i, 1)
                        break
                    }
                }
            }

            this.selectCinemaCount = this.selectCinemaList.length
        },
        resetCinema() {
            this.filterCinemaList.forEach(item => {
                // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
                item.checked = false;
            })
            this.searchCinemaText = ''
            this.selectCinemaCount = 0
            this.selectCinemaList = []
        },
        saveCinema() {
            let cityName = ''
            let cityId = []
            this.selectCinemaList.forEach(item => {
                cityId.push(item.id)
            })
            if (this.selectCinemaCount === 0) {
                if (this.src == 1) {
                    cityName = '设置包含城市'
                } else {
                    cityName = '设置不包含城市'
                }
            } else {
                if (this.src == 1) {
                    cityName = '包含' + this.selectCinemaCount + '个影院'
                } else {
                    cityName = '不包含' + this.selectCinemaCount + '个影院'
                }
            }
            this.ruleInfo[this.src == 1 ? 'include_cinema_desc' : 'exclude_cinema_desc'] = cityName
            this.ruleInfo[this.src == 1 ? 'include_cinema_scope' : 'exclude_cinema_scope'] = cityId
            this.ruleInfo.select_cinema = this.selectCinemaList
            console.log(this.ruleInfo)
            this.selectCinema = false
        },
        onCinemaClick() {
            if (this.selectCinemaList.length > 0) {
                this.showSelectCinema = true
            }
        },

        // 影厅设置
        showHallSelect(src) {
            this.selectHall = true;
            this.src = src;
            this.selectCount = 0;
            this.selectHallList = []
            this.filterHallList = []

            let curHallList = [];
            if (src === 1) {
                curHallList = this.ruleInfo.include_hall_scope
            } else {
                curHallList = this.ruleInfo.exclude_hall_scope
            }
            console.log(this.defaultHallList)
            this.defaultHallList.forEach(item => {
                let checked = false
                if (curHallList != null && curHallList.indexOf(item.name) != -1) {
                    checked = true;
                    this.selectCount++;
                    this.selectHallList.push(item.name)
                }

                this.filterHallList.push({
                    label: item.name,
                    value: item.name,
                    checked: checked,
                })
            })
        },
        hallCheck(index) {
            this.filterHallList[index].checked = !this.filterHallList[index].checked;
            if (this.filterHallList[index].checked) {
                this.selectHallList.push(this.filterHallList[index].value)
                this.selectCount++
            } else {
                this.selectHallList.forEach((item, index) => {
                    if (item === this.filterHallList[index].value) {
                        this.selectHallList.splice(index, 1)
                    }
                })
                this.selectCount--
            }

        },
        resetHall() {
            this.filterHallList.forEach(item => {
                // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
                item.checked = false;
            })
            this.selectHallList = []
            this.selectCount = 0
        },
        searchHallClick() {

            this.filterHallList = []
            this.defaultHallList.forEach(item => {

                if (item.name.indexOf(this.searchText) != -1) {
                    let checked = false
                    this.selectHallList.forEach(item1 => {
                        if (item1.name === item.name) {
                            checked = true
                            return
                        }
                    })
                    this.filterHallList.push({
                        label: item.name,
                        value: item.name,
                        checked: checked,
                    })
                }

            })
        },
        saveHall() {
            let hallName = ''
            this.selectHallList.forEach(item => {
                hallName += item + ','
            })

            if (hallName === '') {
                if (this.src == 1) {
                    hallName = '设置包含影厅'
                } else {
                    hallName = '设置不包含影厅'
                }

            }
            this.ruleInfo[this.src == 1 ? 'include_hall_desc' : 'exclude_hall_desc'] = hallName
            this.ruleInfo[this.src == 1 ? 'include_hall_scope' : 'exclude_hall_scope'] = this.selectHallList

            this.selectHall = false
            console.log(this.ruleInfo)
        },
        clearHallClick() {
            // 清除省份搜索
            this.searchText = '';
            // this.filterGplineList = this.gplineList;
            this.filterHallList = []

            this.defaultHallList.forEach(item => {
                // item.value是否在this.ruleInfo.include_province_scope中，
                let checked = false
                // 判断是否在选中省份中

                this.selectHallList.forEach(item2 => {
                    if (item2 === item.value) {
                        checked = true;
                        return
                    }
                })

                this.filterHallList.push({
                    label: item.name,
                    value: item.name,
                    checked: checked,
                })
            })
        },

        // 影片设置
        showMovieSelect(src) {
            this.selectMovie = true;
            this.src = src;
            this.selectCount = 0;
            this.selectMovieList = []
            this.filterMovieList = []

            let curMovieList = [];
            if (src === 1) {
                curMovieList = this.ruleInfo.include_movie_scope
            } else {
                curMovieList = this.ruleInfo.exclude_movie_scope
            }
            this.movieList.forEach(item => {
                let checked = false
                if (curMovieList != null && curMovieList.indexOf(item.id) != -1) {
                    checked = true;
                    this.selectCount++;
                    this.selectMovieList.push({
                        name: item.name,
                        id: item.id
                    })
                }

                this.filterMovieList.push({
                    label: item.name,
                    value: item.id,
                    checked: checked,
                })
            })
        },
        movieCheck(index) {
            this.filterMovieList[index].checked = !this.filterMovieList[index].checked;
            if (this.filterMovieList[index].checked) {
                this.selectMovieList.push({
                    name: this.filterMovieList[index].label,
                    id: this.filterMovieList[index].value
                })
                this.selectCount++
            } else {
                this.selectMovieList.forEach((item, index1) => {
                    // console.log(item)
                    // console.log(this.filterMovieList[index])
                    if (item.id === this.filterMovieList[index].value) {
                        this.selectMovieList.splice(index1, 1)
                    }
                })
                this.selectCount--
            }
            // console.log(this.selectMovieList)

        },
        resetMovie() {
            this.filterMovieList.forEach(item => {
                // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
                item.checked = false;
            })
            this.selectMovieList = []
            this.selectCount = 0
        },
        searchMovieClick() {

            this.filterMovieList = []
            this.movieList.forEach(item => {

                if (item.name.indexOf(this.searchText) != -1) {
                    let checked = false
                    this.selectMovieList.forEach(item1 => {
                        if (item1.id === item.id) {
                            checked = true
                            return
                        }
                    })
                    this.filterMovieList.push({
                        label: item.name,
                        value: item.id,
                        checked: checked,
                    })
                }

            })
        },
        saveMovie() {
            let name = ''
            let ids = []
            this.selectMovieList.forEach(item => {
                name += item.name + ','
                ids.push(item.id)
            })

            if (name === '') {
                if (this.src == 1) {
                    name = '设置包含影片'
                } else {
                    name = '设置不包含影片'
                }

            }
            this.ruleInfo[this.src == 1 ? 'include_movie_desc' : 'exclude_movie_desc'] = name
            this.ruleInfo[this.src == 1 ? 'include_movie_scope' : 'exclude_movie_scope'] = ids

            this.selectMovie = false
            console.log(this.ruleInfo)
        },
        clearMovieClick() {
            // 清除省份搜索
            this.searchText = '';
            // this.filterGplineList = this.gplineList;
            this.filterMovieList = []

            this.movieList.forEach(item => {
                // item.value是否在this.ruleInfo.include_province_scope中，
                let checked = false
                // 判断是否在选中省份中

                this.selectMovieList.forEach(item2 => {
                    if (item2.id === item.id) {
                        checked = true;
                        return
                    }
                })

                this.filterMovieList.push({
                    label: item.name,
                    value: item.id,
                    checked: checked,
                })
            })
        },

        // 时间
        changeTime(from) {
            console.log(from)
            this.selectTime = true;
            this.src = from;
            if (from == 1) {
                this.currentTime = this.ruleInfo.begin_time;
            } else {
                this.currentTime = this.ruleInfo.end_time;
            }
        },
        timeCheck(value) {
            console.log(value);
            if (this.src == 1) {
                this.ruleInfo.begin_time = value;
            } else {
                this.ruleInfo.end_time = value;
            }
            this.selectTime = false;
        },

        // 日期
        changeDate(from) {
            console.log(from)
            this.selectDate = true;
            this.src = from;
            var curDate = ''
            if (from == 1) {
                curDate = this.ruleInfo.begin_date;
            } else {
                curDate = this.ruleInfo.end_date;
            }
            if (curDate != '') {
                this.currentDate = new Date(curDate);
            }

            console.log(this.currentDate)
        },
        dateCheck(value) {
            var date = this.formatDate(value)
            if (this.src == 1) {
                this.ruleInfo.begin_date = date;
            } else {
                this.ruleInfo.end_date = date;
            }
            this.selectDate = false;
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}/${month}/${day}`;
        },

        // 星期设置
        showWeekSelect() {
            this.selectWeek = true;
            this.selectCount = 0;
            this.selectWeekList = []
            this.filterWeekList = []

            let curWeekList = this.ruleInfo.week_days;

            this.weekList.forEach(item => {
                let checked = false
                if (curWeekList != null) {
                    curWeekList.forEach(item1 => {
                        if (item1 === item.label) {
                            checked = true
                            return
                        }
                    })
                }

                if (checked) {
                    this.selectCount++;
                    this.selectWeekList.push({
                        name: item.label,
                        id: item.id
                    })
                }

                this.filterWeekList.push({
                    name: item.label,
                    value: item.id,
                    checked: checked,
                })
            })
            console.log(this.filterWeekList)
        },
        weekCheck(index) {
            console.log(this.filterWeekList[index])
            this.filterWeekList[index].checked = !this.filterWeekList[index].checked;
            console.log(this.filterWeekList[index])
            if (this.filterWeekList[index].checked) {
                this.selectWeekList.push({
                    name: this.filterWeekList[index].name,
                    id: this.filterWeekList[index].value
                })
                this.selectCount++
            } else {
                this.selectWeekList.forEach((item, index1) => {
                    if (item.name === this.filterWeekList[index].name) {
                        this.selectWeekList.splice(index1, 1)
                    }
                })
                this.selectCount--
            }

            console.log(this.selectWeekList)

        },
        resetWeek() {
            this.filterWeekList.forEach(item => {
                // item.value是否在this.selectProvinces中，如果在且选中，则设置checked=true
                item.checked = false;
            })
            this.selectWeekList = []
            this.selectCount = 0
        },

        saveWeek() {

            console.log(this.selectWeekList)
            let name = ''
            let weeks = []
            this.selectWeekList.forEach(item => {
                name += item.name + '、'
                weeks.push(item.name)
            })

            if (name === '') {
                name = '设置包含星期'
            }
            this.ruleInfo['week_days'] = weeks
            this.ruleInfo.week_desc = name

            this.selectWeek = false
        },
        // 保存配置
        saveConfig() {
            console.log(this.ruleInfo)
            console.log(this.configId)

            const data = {
                'name': this.ruleInfo.name,

                'hall_scope_type': 1,
                // 'hall_name': this.addConfigDialogForm.hall_name,
                'movie_scope_type': 1,
                'province_scope_type': 1,
                'city_scope_type': 1,
                'cinema_scope_type': 1,
                // 'begin_price': this.addConfigDialogForm.begin_price,
                // 'end_price': this.addConfigDialogForm.end_price,
                // 'begin_ticket': this.addConfigDialogForm.begin_ticket,
                // 'end_ticket': this.addConfigDialogForm.end_ticket,
                'date_scope': [],
                'time_scope': [],
                'week_days': this.ruleInfo.week_days,
                'bid_type': this.ruleInfo.bid_type,
                'hall_value': [],
                'status': this.ruleInfo.status,
            }
            data.time_scope.push(this.ruleInfo.begin_time)
            data.time_scope.push(this.ruleInfo.end_time)

            data.date_scope.push(this.ruleInfo.begin_date)
            data.date_scope.push(this.ruleInfo.end_date)

            if (this.ruleInfo.bid_type === 2) {
                // 影厅报价
            } else {
                data.discount = parseFloat(this.ruleInfo.discount) || 0
            }

            if (this.ruleInfo.gp_line !== 0 && this.ruleInfo.gp_line !== '') {
                data.gp_line = this.ruleInfo.gp_line
            }

            if (this.ruleInfo.begin_price !== '' && this.ruleInfo.begin_price > 0) {
                data.begin_price = this.ruleInfo.begin_price
            }

            if (this.ruleInfo.end_price !== '' && this.ruleInfo.end_price > 0) {
                data.end_price = this.ruleInfo.end_price
            }

            if (this.ruleInfo.ticket_num !== null && this.ruleInfo.ticket_num.length > 0) {
                // 按值排序，从小到大
                this.ruleInfo.ticket_num.sort(function (a, b) {
                    return a - b
                })
                if (this.ruleInfo.ticket_num[0] > 0) {
                    // 0为全部，未设置0，说明指定了区间，按最小最大组成区间
                    data.begin_ticket = this.ruleInfo.ticket_num[0]
                    data.end_ticket = this.ruleInfo.ticket_num[this.ruleInfo.ticket_num.length - 1]
                }
            }

            // 电影
            if (this.ruleInfo.include_movie_scope !== null && this.ruleInfo.include_movie_scope.length > 0) {
                data.movie_scope_type = 2
                data.movie_id = this.ruleInfo.include_movie_scope
            } else if (this.ruleInfo.exclude_movie_scope !== null && this.ruleInfo.exclude_movie_scope.length > 0) {
                data.movie_scope_type = 3
                data.movie_id = this.ruleInfo.exclude_movie_scope
            }
            // 省份
            if (this.ruleInfo.include_province_scope !== null && this.ruleInfo.include_province_scope.length > 0) {
                data.province_scope_type = 2
                data.province_id = this.ruleInfo.include_province_scope
            } else if (this.ruleInfo.exclude_movie_scope !== null && this.ruleInfo.exclude_movie_scope.length > 0) {
                data.province_scope_type = 3
                data.province_id = this.ruleInfo.exclude_province_scope
            }

            // 城市
            if (this.ruleInfo.include_city_scope !== null && this.ruleInfo.include_city_scope.length > 0) {
                data.city_scope_type = 2
                data.city_id = this.ruleInfo.include_city_scope
            } else if (this.ruleInfo.exclude_city_scope !== null && this.ruleInfo.exclude_city_scope.length > 0) {
                data.city_scope_type = 3
                data.city_id = this.ruleInfo.exclude_city_scope
            }

            // 影院
            if (this.ruleInfo.include_cinema_scope !== null && this.ruleInfo.include_cinema_scope.length > 0) {
                data.cinema_scope_type = 2
                data.cinema_id = this.ruleInfo.include_cinema_scope
            } else if (this.ruleInfo.exclude_cinema_scope !== null && this.ruleInfo.exclude_cinema_scope.length > 0) {
                data.cinema_scope_type = 3
                data.cinema_id = this.ruleInfo.exclude_cinema_scope
            }

            // 影厅
            if (this.ruleInfo.include_hall_scope !== null && this.ruleInfo.include_hall_scope.length > 0) {
                data.hall_scope_type = 2
                data.hall_name = this.ruleInfo.include_hall_scope
            } else if (this.ruleInfo.exclude_hall_scope !== null && this.ruleInfo.exclude_hall_scope.length > 0) {
                data.hall_scope_type = 3
                data.hall_name = this.ruleInfo.exclude_hall_scope
            }
            if (this.configId === undefined || this.configId === 0) {
                // 新增
                data.type = this.configType

                Api.addConfig(data).then(res => {
                    this.$notify({
                        type: 'success',
                        message: '操作成功'
                    });
                    this.goBack();
                })

            } else {
                // 更新
                data.type = this.ruleInfo.type
                data.id = this.ruleInfo.id

                Api.updateConfig(data).then(res => {
                    this.$notify({
                        type: 'success',
                        message: '操作成功'
                    });
                    this.goBack();
                })
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.home-wrap {
    height: 100%;
    background-color: #f3f3f3;

    .main-card-wrap {
        background-color: white;
        -moz-box-shadow: 2px 2px 3px #ccc;
        -webkit-box-shadow: 2px 2px 3px #ccc;
        box-shadow: 2px 2px 3px #ccc;

        .left {
            .title {
                font-size: 16px;
            }

            .btn {
                height: 34px;
                width: 120px;
                color: white;
                margin-top: 6px;
                border-radius: 4px;
                background-color: #8565bd;
            }
        }

        .right {
            margin-right: 8px
        }
    }
}

.custom-checkbox {
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    padding: 2px 8px;
    /* 根据需要调整内边距 */
    border: 1px solid #dcdfe6;
    /* 边框颜色 */
    border-radius: 8px;
    /* 圆角边框 */
    display: inline-block;
    /* 使元素内联显示 */
    line-height: 1.5;
    /* 行高 */
}

/* 选中状态样式 */
.custom-checkbox.checked {
    color: #1989fa;
    /* 选中时的文字颜色 */
    background-color: #ecf5ff;
    /* 选中时的背景颜色 */
    border-color: #1989fa;
    /* 选中时的边框颜色 */
}

.bottom-row {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.custom-cell {
    .van-cell__value {
        min-width: 70%;
    }
}
</style>
