<template>
<div>
    <van-nav-bar title="待出票列表" fixed :placeholder="true" />
    <div class="home-wrap">
        <van-pull-refresh v-model="refresh" @refresh="onRefresh" style="height: 100% !important;">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div class="main-card-wrap flex-between" v-for="item in tableData" @click="orderDetail(item.order_id)">
                    <div class="left flex-column flex-around">
                        <div class="title">{{item.movie_name}} <span class="priceTitle">({{item.channel_price}} * {{item.quantity}})</span></div>
                        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                        <div class="subtitle">{{item.city_name}} | {{item.cinema_name}}</div>
                        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                        <div class="otherTitle">{{item.play_time_text}} | {{item.hall_name}}</div>
                        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }" />
                        <div class="otherTitle">
                            {{item.seats}}
                        </div>
                        <div style="display: flex;align-items: center;margin-top: 5px;">
                            <div class="priceTitle">竞价成功：{{item.bid_price_text}}</div>
                            <div style="margin-left: auto;font-size: small;color: blue;">{{ item.bid_config_name }}</div>
                            <!-- <span class="priceTitle">竞价成功：{{item.bid_price_text}}     {{ item.bid_config_name }}</span> -->
                        </div>
                    </div>
                    <div class="right flex-middle flex-center">
                        <van-image width="90px" height="120px" fit="fit" :src="item.logo+'_200x200'" />
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</div>
</template>

<script>
import Api from '@/api-service'
export default {
    name: "Wait-Ticket",
    data() {
        return {
            tableData: [],
            queryData: {
                page: 0,
                pageSize: 10
            },
            loading: false,
            finished: false,
            refresh: false,
        }
    },
    created() {
        this.queryData.page++;
        this.fetchData();
    },
    methods: {
        onRefresh() {
            this.queryData.page = 0;
            this.finished = false;
            this.loading = true;
            this.onLoad();
        },

        fetchData() {
            Api.getWaitOrderList(this.queryData).then(res => {
                this.loading = false;
                res.list.forEach(item => {
                    this.tableData.push(item);
                })
                if (res.list.length < this.queryData.pageSize) {
                    this.finished = true;
                }
            }).catch(err => {
                this.loading = false;
            })
        },
        onLoad() {
            setTimeout(() => {
                if (this.refresh) {
                    this.tableData = [];
                    this.refresh = false;
                }
                this.queryData.page++;
                this.fetchData()
            }, 3000)
        },
        orderDetail(id) {
            this.$router.push({
                path: '/order/detail',
                query: {
                    id: id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.home-wrap {
    height: 100%;
    background-color: #f3f3f3;

    .main-card-wrap {
        margin: 10px 10px;
        padding: 16px;
        border-radius: 6px;
        background-color: white;
        -moz-box-shadow: 2px 2px 3px #ccc;
        -webkit-box-shadow: 2px 2px 3px #ccc;
        box-shadow: 2px 2px 3px #ccc;

        .left {
            .title {
                font-size: 18px;
            }

            .subtitle {
                font-size: 14px;
                margin-top: 6px;
            }

            .priceTitle {
                font-size: 16px;
                color: #c75450;

            }

            .otherTitle {
                font-size: 14px;
                margin-top: 6px;
                color: #999;
            }

            .btn {
                height: 34px;
                width: 120px;
                color: white;
                margin-top: 6px;
                border-radius: 4px;
                background-color: #8565bd;
            }
        }

        .right {
            margin-right: 8px
        }
    }
}
</style>
