const constant = {
    GET_LOGIN_CODE_URL: '/houtai/ct/login/code/get',
    // LOGIN_URL: '/houtai/ct/login',
    // GET_USER_INFO_URL: '/houtai/ct/user/info/get',
    // GET_ORDER_LIST:'/houtai/ct/order/list',
    // GET_ORDER_DETAIL:'/houtai/ct/order/get',
    // TAKE_ORDER:'/houtai/ct/order/take',
    // GET_WAIT_ORDER_LIST:'/houtai/ct/order/wait',
    // GET_PROCESSED_ORDER_LIST:'/houtai/ct/order/processed',
    // UPLOAD_ORDER_TICKET_CODE:'/houtai/ct/order/upload/ticket/code',
    // UNLOCK_SEAT:'/houtai/ct/order/unlock/seat',
    // GET_CINEMA_RULE_LIST:'/houtai/ct/cinema/rule/list',
    // SET_CINEMA_RULE_STATUS:'/houtai/ct/cinema/rule/status/set',
    // UPLOAD_IMAGE:'/houtai/uploadImg',

    LOGIN_URL: '/public/user/login',
    REQ_WEIXIN_OPEN_ID_GET: '/public/user/wxLogin',
    GET_USER_INFO_URL: '/public/user/info',
    GET_ORDER_LIST:'/api/order/getTradeOrderList',
    TAKE_ORDER:'/api/order/bidOrder/:id',
    UNBID_ORDER:'/api/order/unBidOrder/:id',
    BACK_ORDER:'/api/order/backOrder/:id',
    GET_WAIT_ORDER_LIST:'/api/order/getBidedOrderList',
    GET_ORDER_DETAIL:'/api/order/getTradeOrderDetail/',
    GET_SEAT_PIC:'/api/order/getSeatPic',
    UPLOAD_IMAGE:'/api/ticket/uploadFile',
    UPLOAD_ORDER_TICKET_CODE:'/api/order/saveTicket',
    UNLOCK_SEAT:'/api/order/unlockSeat',
    GET_PROCESSED_ORDER_LIST:'/api/order/getOrderList',

    SET_CINEMA_RULE_STATUS:'/api/config/updateConfigStatus',
    SET_AUTO_STATUS:'/api/setting/updateSetting',
    GET_WITHDRAW_AMOUNT:'/api/withdraw/getCanWithdrawAmount',
    GET_WITHDRAW_LIST:'/api/withdraw/getList',
    APPLY_WITHDRAW:'/api/withdraw/applyWithdraw',
    HAS_NEW_ORDER:'/api/order/hasNewOrder',
    HAS_MY_ORDER:'/api/order/hasMyOrder',
    CHANGE_PWD:'/api/setting/changePwd',
    MAKE_CODE:'/public/user/code',
    BID_RECORD:'/api/order/getBidRecords',

    CITY_LIST:'/api/cinema/getCitys',
    PROVINCE_LIST:'/api/cinema/getProvinces',
    PROVINCE_CITY_LIST:'/api/cinema/getProvincesWithCity',
    GPLINE_LIST:'/api/cinema/getGplines',
    CINEMA_LIST:'/api/cinema/getCinemas',
    DEFAUL_HALL_LIST:'/api/cinema/getDefaultHalls',
    MOVIE_LIST:'/api/movie/channel/getMovieList',
    
    GET_CINEMA_RULE_LIST:'/api/config/getConfigList',
    GET_RULE_DETAIL:'/api/config/getConfigDetail',
    ADD_RULE:'/api/config/addConfig',
    UPDATE_RULE:'/api/config/updateConfig',
    DELETE_RULE:'/api/config/deleteConfig',
    UPDATE_RULE_STATUS:'/api/config/updateConfigStatus',
};

export default constant;
